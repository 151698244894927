import React from "react"
import cx from "clsx"

import { useAnimateCover, useSetHeroHeight } from "features/hero/hooks"
import { getCoverPositionInlineStyle, getCoverURLBySize, getResponsiveSizesString } from "features/cover/utils"
import { getFormattedDate } from "features/gallery/utils"

import styles from "./left.module.scss"
import { CoverImageSources } from "@app/features/gallery/components/cover-image-sources"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"

function Left({ cover, name, date, coverPosition, locale }) {
  const { isCoverLoaded, image } = useAnimateCover()
  const heroRef = useSetHeroHeight()

  return (
    <section ref={heroRef} className={styles["root"]}>
      {cover && (
        <picture>
          <CoverImageSources cover={cover} />
          <GoogleImage
            src={getCoverURLBySize(cover, "l")}
            alt=""
            ref={image.ref}
            className={cx(styles["cover"], {
              [styles["loaded"]]: isCoverLoaded,
            })}
            style={getCoverPositionInlineStyle(coverPosition)}
            onLoad={image.onLoad}
            onError={image.onError}
          />
        </picture>
      )}

      <div className={styles["overlay"]}></div>
      <div className={styles["content"]}>
        <div className={styles["description"]}>
          <h1 className={cx(styles["title"], { [styles["animated"]]: isCoverLoaded })}>{name}</h1>
          {date && (
            <p className={cx(styles["subtitle"], { [styles["animated"]]: isCoverLoaded })}>
              {getFormattedDate(date, locale)}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

export { Left }
