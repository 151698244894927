import format from "date-fns/format"
import parse from "date-fns/parse"
import ru from "date-fns/locale/ru"
import enUS from "date-fns/locale/en-US"
import uk from "date-fns/locale/uk"

const DATE_LOCALES = { ru: ru, en: enUS, ua: uk }
const CLIENT_DATE_FORMAT = "dd MMMM yyyy"
const CLIENT_DATE_FORMAT_EN = "MMMM dd, yyyy"
const SERVER_DATE_FORMAT = "dd.MM.yyyy"

//Получить отформатированную дату, полученную с бэкенда 21.07.1992 => 21 июля 1992
function getFormattedDate(date, locale = "ru") {
  if (!date) return ""

  return format(
    parse(date, SERVER_DATE_FORMAT, new Date(), {
      locale: DATE_LOCALES[locale],
    }),
    CLIENT_DATE_FORMAT,
    {
      locale: DATE_LOCALES[locale],
    }
  )
}

//Получить отформатированную дату, полученную с бэкенда 21.11.1992 => 21 ноябрь 1992 / November 21, 1992
function getFormattedDateVariousType(date, locale = "ru") {
  if (!date) return ""

  const typeDateFormat = locale === "en" ? CLIENT_DATE_FORMAT_EN : CLIENT_DATE_FORMAT

  return format(
    parse(date, SERVER_DATE_FORMAT, new Date(), {
      locale: DATE_LOCALES[locale],
    }),
    typeDateFormat,
    {
      locale: DATE_LOCALES[locale],
    }
  )
}

export { getFormattedDate, getFormattedDateVariousType }
