import * as React from "react"
import { useI18n } from "next-localization"
import cn from "clsx"

import { sendFeedback } from "@app/features/review/api"

import { useReviewModal } from "@app/features/review/stores"
import { useGallery, useGlobalState } from "@app/contexts"

import { SimpleModal } from "@app/ui/modal"
import { Textarea } from "@app/ui/textarea"
import { Button } from "@app/ui/button"
import { Input } from "@app/ui/input"

import styles from "./review-modal.module.scss"

const ReviewModal = () => {
  const [isSubmiting, setIsSubmiting] = React.useState(false)
  const [isSuccessfullySumbited, setIsSuccessfullySubmited] = React.useState(false)
  const [error, setError] = React.useState({})

  const { isReviewModalVisible, hideReviewModal } = useReviewModal()
  const {
    userAgent: { isIos },
  } = useGlobalState()

  const {
    gallery: { id: galleryId },
  } = useGallery()

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    const elements = event.target["elements"]
    const name = elements.name.value.trim()
    const comment = elements.comment.value.trim()

    if (!comment || !name) {
      setError(() => ({ comment: !comment, name: !name }))
      setIsSubmiting(false)

      return
    }

    if (isSubmiting) return

    setIsSubmiting(true)

    await sendFeedback({
      text: comment,
      name,
      galleryId,
    })

    setIsSubmiting(false)
    setIsSuccessfullySubmited(true)
  }

  function handleCloseClick() {
    hideReviewModal()
    setIsSubmiting(false)
    setIsSuccessfullySubmited(false)
    setError(false)
  }

  function handleFocus(event) {
    setError((error) => ({ ...error, [event.target.name]: false }))
  }

  const i18n = useI18n()

  return (
    <SimpleModal open={isReviewModalVisible} onClose={handleCloseClick}>
      <form onSubmit={handleSubmit} className={styles["form"]}>
        {!isSuccessfullySumbited && (
          <React.Fragment>
            <h3 className={styles["title"]}>{i18n.t("reviewModal.title")}</h3>
            <p className={styles["description"]}>{i18n.t("reviewModal.description")}</p>
            <Input
              type="text"
              placeholder={i18n.t("reviewModal.namePlaceholder")}
              className={cn(styles["input"], error["name"] && styles["error"], isIos && styles["scalable-field"])}
              maxLength={100}
              onFocus={handleFocus}
              name="name"
            ></Input>

            <Textarea
              placeholder={i18n.t("reviewModal.commentPlaceholder")}
              className={cn(styles["textarea"], error["comment"] && styles["error"], {
                [styles["scalable-area"]]: isIos,
              })}
              disabled={isSubmiting}
              name="comment"
              onFocus={handleFocus}
              maxLength={2000}
            ></Textarea>
            <Button type="submit" loading={isSubmiting} className={styles["button"]}>
              {i18n.t("reviewModal.action")}
            </Button>
          </React.Fragment>
        )}

        {isSuccessfullySumbited && (
          <React.Fragment>
            <h3 className={styles["title"]}>{i18n.t("reviewModal.successTitle")}</h3>
            <p className={styles["description"]}>{i18n.t("reviewModal.successDescription")}</p>
          </React.Fragment>
        )}
      </form>
    </SimpleModal>
  )
}

export { ReviewModal }
