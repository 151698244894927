import { FunctionComponent } from "react"
import { Spinner } from "@app/ui/spinner"

import styles from "./watermark-loader.module.scss"

type WatermarkLoaderProps = { text: string }

const WatermarkLoader: FunctionComponent<WatermarkLoaderProps> = ({ text }): JSX.Element => {
  return (
    <div className={styles["root"]}>
      <div className={styles["loader"]}>
        <Spinner className={styles["spinner"]} />
      </div>
      <div className={styles["content"]}>{text}</div>
    </div>
  )
}

export { WatermarkLoader }
