import React from "react"
import cx from "clsx"

import { Button } from "ui/button"
import { Input } from "ui/input"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./modal-body.module.scss"

function ModalBody({
  title,
  description,
  validationError,
  login,
  handleSubmit,
  handleChange,
  isSubmitingCS,
  isValidationErrorCS,
  isValidationErrorHidingPhotosCS,
  badEmailError,
}) {
  const handleChangeEmail = (event) => {
    const { target } = event
    target.value = target.value.replace(/ /g, "")
    handleChange(event)
  }

  return (
    <div className={styles["root"]}>
      <form onSubmit={handleSubmit} noValidate>
        <h3 className={styles["title"]}>{title}</h3>
        <p className={styles["text"]}>{description}</p>
        <Input
          type="text"
          autoComplete="email"
          placeholder="Email"
          name="email"
          className={cx(
            {
              [styles["error"]]: isValidationErrorCS,
            },
            QA_CLASS_NAMES.favourites.email
          )}
          disabled={isSubmitingCS}
          onChange={handleChangeEmail}
        ></Input>
        {isValidationErrorCS && <p className={styles["error-message"]}>{validationError}</p>}
        {isValidationErrorHidingPhotosCS && <p className={styles["error-message"]}>{badEmailError}</p>}
        <Button type="submit" loading={isSubmitingCS} className={cx(styles["button"], QA_CLASS_NAMES.favourites.login)}>
          {login}
        </Button>
      </form>
    </div>
  )
}

export { ModalBody }
