import React from "react"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"

import { authUser } from "features/user/utils"
import {
  addPhotoToFavoritesListAR,
  createDefaultFavoritesListAR,
  fetchFavoriteLists,
} from "features/favorites/requests"
import { getCurrentGalleryDefaultFavoritesList } from "features/favorites/utils"
import { useLikedPhoto, useSetFavoriteLists } from "features/favorites/store/hooks"
import { useGallery, useGlobalState } from "@app/contexts"
import { fetchGallery } from "features/gallery/requests"
import { usePhotoCarousel, useSetAllPhotos } from "features/photo-carousel/store/hooks"
import { useNotification } from "features/notification/store/hooks"
import { ADDED_TO_FAVORITES_NOTIFICATION_TYPE } from "features/notification/constants"
import { useAuthorization, useAuthorizationModal, useHidingPhotosAuth } from "@app/features/user/stores"
import { useMobileMenu } from "features/gallery/store/hooks"
import { REQUEST_TYPE } from "@app/api/constants"

import { useLimitModal } from "@app/features/user/stores"
import { checkFavoriteLimit } from "features/favorites/utils"

import { Modal } from "ui/modal"

import { ModalBody } from "@app/features/user/components/modal-body"

import { QA_CLASS_NAMES } from "@app/constants"

function AuthModal() {
  const router = useRouter()

  const { $setActivePhoto } = usePhotoCarousel()
  const { setUserAuthorization } = useAuthorization()
  const {
    isAuthorizationModalVisible,
    hideAuthorizationModal,
    isHidingPhotosModalVisible,
    hideAuthorizationModalHidingPhotos,
  } = useAuthorizationModal()
  const $setFavoriteLists = useSetFavoriteLists()
  const $setAllPhotos = useSetAllPhotos()
  const { $likedPhoto } = useLikedPhoto()
  const { $showNotification } = useNotification()
  const { gallery, setGallery } = useGallery()
  const {
    userAgent: { isIos },
  } = useGlobalState()
  const galleryId = gallery.id

  const { showLimitModal } = useLimitModal()
  const { setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()
  const { $isMobileMenuVisible, $toggleMobileMenuVisibility } = useMobileMenu()

  const [isValidationErrorCS, setIsValidationErrorCS] = React.useState(false)
  const [isSubmitingCS, setIsSubmitingCS] = React.useState(false)

  const [isValidationErrorHidingPhotosCS, setIsValidationErrorHidingPhotosCS] = React.useState(false)

  function handleChange() {
    setIsValidationErrorCS(false)
    setIsValidationErrorHidingPhotosCS(false)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const email = event.target.elements.email.value
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0 -9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // /^(([^<>()\[\]\\.,;:\s@"а-яА-Я]+(\.[^<>()\[\]\\.,;:\s@"а-яА-Я]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/
    const isValidEmail = pattern.test(email)

    if (isSubmitingCS) {
      return
    }

    if (isValidEmail) {
      setIsSubmitingCS(true)
      const authStatus = await authUser(email)

      if (!authStatus) {
        setIsSubmitingCS(false)
        return
      }

      setUserAuthorization(true)
      const favoriteLists = await fetchFavoriteLists({ galleryId })

      const currentGalleryDefaultFavoritesList = getCurrentGalleryDefaultFavoritesList(favoriteLists, galleryId)
      const slug = router.query.slug
      const domain = window.location.host

      // Hiding Photos
      if (authStatus) {
        const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })

        // Если открыто модальное окно авторизации Скрытия фото
        if (isHidingPhotosModalVisible) {
          if (gallery?.isHidingPhotosEnabled && !gallery?.isGrantedHidingPhotos) {
            setIsValidationErrorHidingPhotosCS(true)
          }

          if (gallery?.isHidingPhotosEnabled && gallery?.isGrantedHidingPhotos) {
            if ($isMobileMenuVisible) {
              // на мобайле авторизация в моб меню, след закрываем его после авторизации
              $toggleMobileMenuVisibility()
            }

            hideAuthorizationModalHidingPhotos()
            setUserAuthorizationHidingPhotos(true)
            // Обновляем gallery
            setGallery(gallery)
          }
        }

        // Окно авторизации не открыто, а email для Избранного подошел и для Скрытия фото
        if (!isHidingPhotosModalVisible && gallery?.isHidingPhotosEnabled && gallery?.isGrantedHidingPhotos) {
          setUserAuthorizationHidingPhotos(true)
          // Обновляем gallery
          setGallery(gallery)
        }
      }

      if (currentGalleryDefaultFavoritesList) {
        let allPhotos = []

        const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })

        if (gallery) {
          gallery.scenes.forEach((scene) => {
            allPhotos = [...allPhotos, ...scene.mediaFiles]
          })
        }

        $setAllPhotos(allPhotos)
        $setFavoriteLists(favoriteLists)
        hideAuthorizationModal()
        setIsSubmitingCS(false)

        if ($likedPhoto.photoId && $likedPhoto.imageSrc) {
          const isFull = checkFavoriteLimit(galleryId, favoriteLists)

          if (!isFull) {
            await addPhotoToFavoritesListAR($likedPhoto.photoId, currentGalleryDefaultFavoritesList.id)
            $showNotification({
              type: ADDED_TO_FAVORITES_NOTIFICATION_TYPE,
              image: $likedPhoto.imageSrc,
              link: `/favorites-list/${currentGalleryDefaultFavoritesList.id}`,
            })
            let allPhotos = []
            const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })
            if (gallery) {
              gallery.scenes.forEach((scene) => {
                allPhotos = [...allPhotos, ...scene.mediaFiles]
              })
            }

            $setAllPhotos(allPhotos)
            const likedPhotoIndex = allPhotos.findIndex((photo) => photo.id === $likedPhoto.photoId)
            $setActivePhoto(likedPhotoIndex)
          } else {
            showLimitModal()
          }
        }

        return
      }

      if (!currentGalleryDefaultFavoritesList) {
        await createDefaultFavoritesListAR(galleryId)
        const favoriteLists = await fetchFavoriteLists({ galleryId })
        $setFavoriteLists(favoriteLists)
        hideAuthorizationModal()
        setIsSubmitingCS(false)

        if ($likedPhoto.photoId && $likedPhoto.imageSrc) {
          const createdDefaultFavoritesList = getCurrentGalleryDefaultFavoritesList(favoriteLists, galleryId)
          await addPhotoToFavoritesListAR($likedPhoto.photoId, createdDefaultFavoritesList.id)
          $showNotification({
            type: ADDED_TO_FAVORITES_NOTIFICATION_TYPE,
            image: $likedPhoto.imageSrc,
            link: `/favorites-list/${createdDefaultFavoritesList.id}`,
          })
          const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })
          let allPhotos = []

          if (gallery) {
            gallery.scenes.forEach((scene) => {
              allPhotos = [...allPhotos, ...scene.mediaFiles]
            })
          }

          $setAllPhotos(allPhotos)
          const likedPhotoIndex = allPhotos.findIndex((photo) => photo.id === $likedPhoto.photoId)
          $setActivePhoto(likedPhotoIndex)
          const newFavoriteLists = await fetchFavoriteLists({ galleryId })
          $setFavoriteLists(newFavoriteLists)
        }
        return
      }
    } else {
      setUserAuthorization(false)
      setIsValidationErrorCS(true)
    }
  }

  const i18n = useI18n()

  return (
    <React.Fragment>
      <Modal
        open={isAuthorizationModalVisible}
        onClose={hideAuthorizationModal}
        crossClassName={QA_CLASS_NAMES.favourites.close}
      >
        <ModalBody
          title={i18n.t("common.favorites")}
          description={i18n.t("authModal.description")}
          validationError={i18n.t("authModal.validationError")}
          login={i18n.t("common.login")}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          isSubmitingCS={isSubmitingCS}
          isValidationErrorCS={isValidationErrorCS}
        ></ModalBody>
      </Modal>
      <Modal
        open={isHidingPhotosModalVisible}
        onClose={hideAuthorizationModalHidingPhotos}
        crossClassName={QA_CLASS_NAMES.favourites.close}
      >
        <ModalBody
          title={i18n.t("hidingPhotosModal.title")}
          description={i18n.t("hidingPhotosModal.description")}
          validationError={i18n.t("hidingPhotosModal.validationError")}
          login={i18n.t("common.login")}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          isSubmitingCS={isSubmitingCS}
          isValidationErrorCS={isValidationErrorCS}
          isValidationErrorHidingPhotosCS={isValidationErrorHidingPhotosCS}
          badEmailError={i18n.t("hidingPhotosModal.badEmailError")}
        ></ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export { AuthModal }
