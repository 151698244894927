import React from "react"

function useSetHeight() {
  const heroRef = React.useRef(null)

  React.useEffect(() => {
    const header = document.querySelector("#header")

    function setHeroHeight() {
      const headerHeight = header.getBoundingClientRect().height
      const windowHeight = window.innerHeight
      const isHeroMounted = Boolean(heroRef.current)

      if (isHeroMounted) {
        heroRef.current.style.height = `${windowHeight - headerHeight}px`
      }
    }

    setTimeout(() => {
      if (header) {
        setHeroHeight(header)
        window.addEventListener("resize", setHeroHeight)
      }
    }, 0)
    return () => window.removeEventListener("resize", setHeroHeight)
  }, [])
  return heroRef
}

export { useSetHeight }
