import React from "react"
import { Link as AnchorLink } from "react-scroll"
import cx from "clsx"

import { useAnimateCover, useSetHeroHeight, useParallaxAnimation } from "features/hero/hooks"
import { getCoverURLBySize, getResponsiveSizesString, getCoverPositionInlineStyle } from "features/cover/utils"
import { getFormattedDate } from "features/gallery/utils"
import { IconWrapper } from "features/theme/icon-wrapper"

import { ArrowDown } from "ui/icons"

import styles from "./invert.module.scss"
import { CoverImageSources } from "@app/features/gallery/components/cover-image-sources"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"

function Invert({ cover, name, date, locale, coverPosition }) {
  const { isCoverLoaded, image } = useAnimateCover()
  const heroRef = useSetHeroHeight()
  const { areaContainerRef, invertedContainerRef, invertedTitleRef, titleRef, compositionRef, coverContainerRef } =
    useParallaxAnimation(image, heroRef)

  const isCoverExist = Boolean(cover)

  return (
    <section ref={heroRef} className={styles["root"]}>
      <div className={styles["area"]} ref={areaContainerRef}>
        <div ref={compositionRef} className={styles["composition"]}>
          {isCoverExist && (
            <picture ref={coverContainerRef} className={styles["cover-container"]}>
              <CoverImageSources cover={cover} />
              <GoogleImage
                src={getCoverURLBySize(cover, "l")}
                alt=""
                ref={image.ref}
                className={cx(styles["cover"], {
                  [styles["loaded"]]: isCoverLoaded,
                })}
                style={getCoverPositionInlineStyle(coverPosition)}
                onLoad={image.onLoad}
                onError={image.onError}
              />
            </picture>
          )}
          {!isCoverExist && (
            <picture ref={coverContainerRef} className={styles["cover-container"]}>
              <img
                alt=""
                ref={image.ref}
                className={styles["cover"]}
                onLoad={image.onLoad}
                onError={image.onError}
              ></img>
            </picture>
          )}

          <div ref={invertedContainerRef} className={styles["inverted-title-container"]}>
            <h1
              ref={invertedTitleRef}
              className={cx(styles["inverted-title"], { [styles["animated-title"]]: isCoverLoaded })}
            >
              {name}
            </h1>
          </div>

          <h1 ref={titleRef} className={cx(styles["title"], { [styles["animated-title"]]: isCoverLoaded })}>
            {name}
          </h1>
        </div>
      </div>

      <div className={styles["content"]}>
        <div className={styles["description"]}>
          {date && (
            <p className={cx(styles["subtitle"], { [styles["animated"]]: isCoverLoaded })}>
              {getFormattedDate(date, locale)}
            </p>
          )}
        </div>
        <AnchorLink
          className={cx(styles["scroll-down"], { [styles["animated"]]: isCoverLoaded })}
          to="header"
          hashSpy={false}
          spy={true}
          smooth={true}
          isDynamic={true}
          duration={500}
        >
          <IconWrapper
            light={<ArrowDown height="8" width="14" stroke="#000" className={styles["arrow-down"]} />}
            dark={<ArrowDown height="8" width="14" stroke="#fff" className={styles["arrow-down"]} />}
          />
        </AnchorLink>
      </div>
    </section>
  )
}

export { Invert }
