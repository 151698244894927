import React from "react"
import { Link as AnchorLink } from "react-scroll"
import cx from "clsx"

import { useAnimateCover, useSetHeroHeight } from "features/hero/hooks"
import { getCoverURLBySize, getResponsiveSizesString } from "features/cover/utils"
import { getFormattedDate } from "features/gallery/utils"
import { IconWrapper } from "features/theme/icon-wrapper"

import { ArrowDown } from "ui/icons"

import styles from "./album.module.scss"
import { CoverImageSources } from "@app/features/gallery/components/cover-image-sources"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"

function Album({ cover, name, date, locale }) {
  const { isCoverLoaded, image } = useAnimateCover()
  const heroRef = useSetHeroHeight()

  return (
    <section ref={heroRef} className={styles["root"]}>
      {cover && (
        <div className={styles["picture-container"]}>
          <picture className={styles["picture"]}>
            <CoverImageSources cover={cover} />
            <GoogleImage
              src={getCoverURLBySize(cover, "l")}
              alt=""
              ref={image.ref}
              className={cx(styles["cover"], {
                [styles["loaded"]]: isCoverLoaded,
              })}
              onLoad={image.onLoad}
              onError={image.onError}
            />
          </picture>
        </div>
      )}

      <div className={styles["content"]}>
        <div className={styles["description"]}>
          <h1 className={cx(styles["title"], { [styles["animated"]]: isCoverLoaded })}>{name}</h1>
          {date && (
            <p className={cx(styles["subtitle"], { [styles["animated"]]: isCoverLoaded })}>
              {getFormattedDate(date, locale)}
            </p>
          )}
        </div>
        <AnchorLink
          className={cx(styles["scroll-down"], { [styles["animated"]]: isCoverLoaded })}
          to="header"
          hashSpy={false}
          spy={true}
          smooth={true}
          isDynamic={true}
          duration={500}
        >
          <IconWrapper
            light={<ArrowDown height="8" width="14" stroke="#000" className={styles["arrow-down"]} />}
            dark={<ArrowDown height="8" width="14" stroke="#fff" className={styles["arrow-down"]} />}
          />
        </AnchorLink>
      </div>
    </section>
  )
}

export { Album }
