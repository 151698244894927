import { api } from "@app/api"

async function sendFeedback({ text, name, galleryId }: { text: string; name: string; galleryId: string }) {
  try {
    await api().post(`/galleries/${galleryId}/comments`, {
      text,
      name,
    })
  } catch (error) {
    console.error(error)
  }
}

export { sendFeedback }
