import { useI18n } from "next-localization"

import { useReviewModal } from "@app/features/review/stores"

import { Button } from "@app/ui/button"

import styles from "./review.module.scss"

const Review = () => {
  const { showReviewModal } = useReviewModal()

  function handleClick() {
    showReviewModal()
  }

  const i18n = useI18n()

  return (
    <section className={styles["root"]}>
      <h2 className={styles["title"]}>{i18n.t("review.title")}</h2>
      <p className={styles["description"]}>{i18n.t("review.description")}</p>
      <Button skin="secondary" className={styles["button"]} onClick={handleClick}>
        {i18n.t("review.action")}
      </Button>
    </section>
  )
}

export { Review }
