import { GallerySettingsInterface } from "@app/features/gallery/types"

function checkFavoritesSectionActivation(gallerySettings: GallerySettingsInterface) {
  if (gallerySettings.hasOwnProperty("isFavoritesSectionActivated")) {
    return gallerySettings.isFavoritesSectionActivated
  }

  return true
}

export { checkFavoritesSectionActivation }
