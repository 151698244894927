import React from "react"
import { useI18n } from "next-localization"

import { useVisibleContextMenu } from "@app/features/context-menu-photo/store"
import { ModalMobile } from "@app/ui/modal-mobile"
import { IS_FIRST_CONTEXT_MENU_MOBILE_STORAGE_KEY } from "@app/features/context-menu-photo/constants"

const ContextMenu = () => {
  const i18n = useI18n()
  const { isVisibleContextMenu, hideContextMenu }: any = useVisibleContextMenu()

  function handleCloseModal() {
    hideContextMenu()
    window.localStorage.setItem(IS_FIRST_CONTEXT_MENU_MOBILE_STORAGE_KEY, JSON.stringify(false))
  }

  const emoji = "🥲"

  return (
    <ModalMobile
      isVisible={isVisibleContextMenu}
      onClose={handleCloseModal}
      title={i18n.t("contextMenu.title")}
      description={i18n.t("contextMenu.description", { emoji })}
      buttonText={i18n.t("contextMenu.buttonText")}
    ></ModalMobile>
  )
}

export { ContextMenu }
