import * as React from "react"
import Head from "next/head"
import { useI18n } from "next-localization"

import styles from "./gallery.module.scss"

import { useGallery } from "@app/contexts"
import { useGlobalState } from "@app/contexts"
import { checkDownloadAvailability } from "@app/features/download/utils"
import { Header } from "@app/features/gallery/components"
import { Album, Banner, Center, Classic, Invert, Left, Line, Split, Top } from "@app/features/hero/components"
import { getFormattedDateVariousType } from "@app/features/hero/utils"
import { HidingMediaFilesHead } from "@app/features/hiding-media-files/components"
import { useSetHidingMediaFilesList, useVisibleMediaFilesCount } from "@app/features/hiding-media-files/hooks"
import { useHidingMediaFilesStore } from "@app/features/hiding-media-files/store"
import { Meta } from "@app/features/meta/components"
import { GALLERY_META_KEY } from "@app/features/meta/constants"
import { getMeta } from "@app/features/meta/utils"
import { PhotoCarousel } from "@app/features/photo-carousel/components"
import { useSetAllPhotos } from "@app/features/photo-carousel/store/hooks"
import { ProductsContainer } from "@app/features/products/components/products-container"
import { ProductsSection } from "@app/features/products/components/products-section"
import { Review } from "@app/features/review/components"
import { useHidingPhotosAuth } from "@app/features/user/stores"
import { Footer } from "@app/ui/footer"
import { Scene } from "@app/ui/pages/gallery/scene"

const Gallery = () => {
  const { gallery } = useGallery()
  const settings = useGlobalState()
  const { t } = useI18n()
  const { locale, isHidingPhotosEnabled, isGrantedHidingPhotos } = settings

  const $setAllPhotos = useSetAllPhotos()
  const $setHidingMediaFilesList = useSetHidingMediaFilesList()

  const { isUserAuthorizedHidingPhotos, setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()
  React.useEffect(() => {
    setUserAuthorizationHidingPhotos(isGrantedHidingPhotos)
  }, [isGrantedHidingPhotos])

  const isCanBeHidden = isHidingPhotosEnabled && isUserAuthorizedHidingPhotos

  const { hidingMediaFilesList } = useHidingMediaFilesStore()
  const hidingMediaFilesListId = hidingMediaFilesList.map((hidden) => hidden.id)
  const isHasHidingMediaFile = isCanBeHidden && Boolean(hidingMediaFilesList.length)
  const [scenesWithHiddenMediaFiles, setScenesWithHiddenMediaFiles] = React.useState([])
  const [scenesWithoutHiddenMediaFiles, setScenesWithoutHiddenMediaFiles] = React.useState([])

  const [galleryUpdate, setGalleryUpdate] = React.useState(gallery)

  React.useEffect(() => {
    setGalleryUpdate(gallery)
  }, [gallery])

  React.useEffect(() => {
    let allMediaFiles = []
    let hidingMediaFiles = []
    galleryUpdate.scenes.forEach((scene) => {
      allMediaFiles = [...allMediaFiles, ...scene.mediaFiles]
      if (isHidingPhotosEnabled) {
        hidingMediaFiles = [...hidingMediaFiles, ...scene.mediaFiles.filter((file) => file.isHidden === true)]
      }
    })

    $setAllPhotos(allMediaFiles)

    if (isHidingPhotosEnabled) {
      $setHidingMediaFilesList(hidingMediaFiles)
    }
  }, [$setAllPhotos, $setHidingMediaFilesList, galleryUpdate.scenes])

  const visibleMediaFilesCount = useVisibleMediaFilesCount({ scenes: galleryUpdate.scenes })

  React.useEffect(() => {
    let cleanupFunction = false
    let scenes = []
    let scenesHidden = []
    galleryUpdate.scenes.forEach((item) => {
      const sceneWithoutHiddenMediaFiles = { ...item }
      sceneWithoutHiddenMediaFiles.mediaFiles = sceneWithoutHiddenMediaFiles.mediaFiles.filter(
        (file) => !hidingMediaFilesListId.includes(file.id)
      )
      scenes.push(sceneWithoutHiddenMediaFiles)

      if (isHidingPhotosEnabled) {
        const sceneWithHiddenMediaFiles = { ...item }
        sceneWithHiddenMediaFiles.mediaFiles = sceneWithHiddenMediaFiles.mediaFiles.filter((file) =>
          hidingMediaFilesListId.includes(file.id)
        )
        scenesHidden.push(sceneWithHiddenMediaFiles)
      }
    })

    // обновление состояния при условии, что компонент не размонтирован
    if (!cleanupFunction) setScenesWithoutHiddenMediaFiles(scenes)
    if (isHidingPhotosEnabled) {
      if (!cleanupFunction) setScenesWithHiddenMediaFiles(scenesHidden)
    }

    // функция очистки useEffect
    return () => (cleanupFunction = true)
  }, [setScenesWithoutHiddenMediaFiles, setScenesWithHiddenMediaFiles, galleryUpdate.scenes, hidingMediaFilesList])

  const isBannerActive = gallery?.deleteAt
  const isSingleScene = gallery.scenes?.length === 1
  const isFirstSceneUnnamed = gallery.scenes[0]?.name === ""
  const isWithoutScenes = isSingleScene && isFirstSceneUnnamed

  const isDownloadEnabled = checkDownloadAvailability(gallery)

  const isCenterDesign = gallery?.coverDesign?.id === 1
  const isSplitDesign = gallery?.coverDesign?.id === 2
  const isLeftDesign = gallery?.coverDesign?.id === 3
  const isAlbumDesign = gallery?.coverDesign?.id === 4
  const isClassicDesign = gallery?.coverDesign?.id === 5
  const isLineDesign = gallery?.coverDesign?.id === 6
  const isTopDesign = gallery?.coverDesign?.id === 7
  const isParallaxDesign = gallery?.coverDesign?.id === 8
  const isDesignEmpty =
    !isCenterDesign &&
    !isSplitDesign &&
    !isLeftDesign &&
    !isAlbumDesign &&
    !isClassicDesign &&
    !isLineDesign &&
    !isTopDesign &&
    !isParallaxDesign

  const isScenesExist = !!gallery?.scenes?.length

  let hasDisplayReview = gallery.isCommentsEnabled && !gallery.isPhotosInProgress

  const i18n = useI18n()

  return (
    <React.Fragment>
      <Meta {...getMeta({ key: GALLERY_META_KEY, settings, gallery, i18n })}></Meta>
      {isBannerActive && <Banner deleteAt={getFormattedDateVariousType(gallery.deleteAt, locale)}></Banner>}

      {(isCenterDesign || isDesignEmpty) && (
        <Center
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Center>
      )}
      {isSplitDesign && (
        <Split
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Split>
      )}
      {isLeftDesign && (
        <Left
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Left>
      )}
      {isAlbumDesign && <Album cover={gallery.cover} name={gallery.name} date={gallery.date} locale={locale}></Album>}
      {isClassicDesign && (
        <Classic
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Classic>
      )}
      {isLineDesign && (
        <Line
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Line>
      )}
      {isTopDesign && (
        <Top
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Top>
      )}
      {isParallaxDesign && (
        <Invert
          cover={gallery.cover}
          name={gallery.name}
          date={gallery.date}
          coverPosition={gallery.settings?.coverPosition}
          locale={locale}
        ></Invert>
      )}

      <Header
        scenes={galleryUpdate.scenes}
        cover={gallery.cover}
        logo={settings.logo}
        logoAlt={settings.logoAlt}
        photographerName={settings.name}
        galleryId={gallery.id}
        galleryName={gallery.name}
        photographerSite={settings.site}
        isWithoutScenes={isWithoutScenes}
        isDownloadEnabled={isDownloadEnabled}
        isHasHidingMediaFile={isHasHidingMediaFile}
        isCanBeHidden={isCanBeHidden}
      ></Header>
      <>
        {!isScenesExist && <p className={styles["no-photos-message"]}>{i18n.t("galleryPage.noPhotos")}</p>}
        {isScenesExist &&
          scenesWithoutHiddenMediaFiles.map((scene) => {
            if (scene.mediaFiles.length) {
              return (
                <Scene
                  key={scene.id}
                  layout={gallery.layout.handle}
                  isWithoutScenes={isWithoutScenes}
                  isDownloadEnabled={isDownloadEnabled}
                  isPhotosInProgress={gallery.isPhotosInProgress}
                  isCanBeHidden={isCanBeHidden}
                  isAllMediaFilesHidden={scene.isAllMediaFilesHidden}
                  {...scene}
                ></Scene>
              )
            }
          })}
        {isHasHidingMediaFile && isCanBeHidden && (
          <HidingMediaFilesHead anchor="hidden-photos-01" title={i18n.t("common.hiddenPhotos")}></HidingMediaFilesHead>
        )}
        {isHasHidingMediaFile &&
          isScenesExist &&
          isCanBeHidden &&
          scenesWithHiddenMediaFiles.map((scene) => {
            if (scene.mediaFiles.length) {
              return (
                <Scene
                  key={scene.id}
                  layout={gallery.layout.handle}
                  isWithoutScenes={isWithoutScenes}
                  isDownloadEnabled={isDownloadEnabled}
                  isPhotosInProgress={gallery.isPhotosInProgress}
                  isCanBeHidden={isCanBeHidden}
                  isAllMediaFilesHidden={scene.isAllMediaFilesHidden}
                  className="is-hidden-photos-scene"
                  isHiddenScene={true}
                  {...scene}
                ></Scene>
              )
            }
          })}
        {gallery.isPrintableProductsEnabled && (
          <ProductsContainer>
            <ProductsSection
              title={t("productsModal.base.title")}
              description={t("productsModal.base.description")}
              onlyAvailable={false}
              position={"footer"}
            />
          </ProductsContainer>
        )}
        {hasDisplayReview && <Review></Review>}
      </>
      <Footer settings={settings}></Footer>
      <PhotoCarousel isDownloadEnabled={isDownloadEnabled} isCanBeHidden={isCanBeHidden}></PhotoCarousel>
    </React.Fragment>
  )
}

export { Gallery }
