import { useState, useEffect } from "react"
import { GetServerSideProps } from "next"
import dynamic from "next/dynamic"
import { I18nProvider } from "next-localization"

import { CompleteSelection } from "features/favorites/components/complete-selection"
import { getCurrentGalleryDefaultFavoritesList } from "features/favorites/utils"

import { REQUEST_TYPE } from "@app/api/constants"
import { IMAGE_PROXY_HOST, IMAGE_STORAGE_ORIGINAL, SIZE } from "@app/constants"
import { GlobalStateProvider } from "@app/contexts"
import { GalleryProvider } from "@app/contexts/gallery"
import { ProductGroupsProvider } from "@app/contexts/product-groups"
import { ContextMenu } from "@app/features/context-menu-photo/components"
import { DownloadModal } from "@app/features/download/components"
import { LimitModal } from "@app/features/favorites/components"
import { fetchFavoriteLists } from "@app/features/favorites/requests"
import { useGetFavoriteLists, useSetFavoriteLists } from "@app/features/favorites/store/hooks"
import { checkFavoritesSectionActivation } from "@app/features/favorites/utils"
import { useSetHeadingsFont, useSetHeadingsSpacing, useSetTheme } from "@app/features/font-customization/hooks"
import { PasswordScreen } from "@app/features/gallery/components"
import { fetchGallery } from "@app/features/gallery/requests"
import { fetchGeneralSettings } from "@app/features/general-settings/requests"
import { Notification } from "@app/features/notification/components"
import { Onboarding } from "@app/features/onboarding/components"
import { checkOnboardingActivation, initPrintOnboarding } from "@app/features/onboarding/utils"
import { ReviewModal } from "@app/features/review/components"
import { useScrollLockController } from "@app/features/scroll/hooks"
import { viewsCountIncrement } from "@app/features/statistics"
import { AuthModal } from "@app/features/user/components"
import { useAuthorization } from "@app/features/user/stores"
import { checkAccessToken } from "@app/features/user/utils"
import { LOCALE } from "@app/localization/constants"
import { GalleryPageProps } from "@app/types"
import { Gallery } from "@app/ui/pages/gallery"
import { useUserAgent } from "@app/utils"
import { getImageType } from "@app/utils"

const SharingModal = dynamic(() => import("@app/features/sharing/components").then((mod) => mod.SharingModal), {
  ssr: false,
})
const ManualDownloadModal = dynamic(
  () => import("@app/features/manual-download/components").then((mod) => mod.ManualDownloadModal),
  { ssr: false }
)

import { SplitTestingProvider } from "@app/contexts/split-testing"
import { TCommentModalProps } from "@app/features/favorites/components"
import { usePhotoCarousel } from "@app/features/photo-carousel/store/hooks"
import { DownloadProductsModal } from "@app/features/products/components/download-products-modal"
import { ProductsModal } from "@app/features/products/components/products-modal"
import { getProducts } from "@app/features/products/server-requests"
import { formatProductGroups } from "@app/features/products/utils"

const CommentModal = dynamic<TCommentModalProps>(
  () => import("@app/features/favorites/components").then((mod) => mod.CommentModal),
  {
    ssr: false,
  }
)

const useDisplayCompleteSelection = ({ favoriteLists, galleryId }) => {
  const [isDisplay, setIsDisplay] = useState(false)
  const [favListId, setFavListId] = useState(null)
  const [countPhotos, setCountPhotos] = useState(0)

  useEffect(() => {
    const currentFavlistId = getCurrentGalleryDefaultFavoritesList(favoriteLists, galleryId)?.id
    if (currentFavlistId) {
      const currentFavoriteList = favoriteLists.find((list) => list.id === currentFavlistId)
      setFavListId(currentFavlistId)
      setIsDisplay(currentFavoriteList?.photos.length > 0 && !currentFavoriteList?.isInitialSelectionCompleted)
      setCountPhotos(currentFavoriteList?.photos.length)
    }
  }, [favoriteLists])

  return [isDisplay, favListId, countPhotos]
}

const GalleryPage = (props: GalleryPageProps) => {
  const {
    domain,
    slug,
    favoriteLists,
    isUserAuth,
    dictionary,
    locale,
    generalSettings,
    gallery,
    productGroups,
    splitTestings,
    isPasswordProtected: isInitialyPasswordProtected,
    userAgentString,
  } = props

  // +Page initialization
  const headingsFontFamily = gallery.font?.handle
  const theme = gallery.theme?.handle

  useSetHeadingsFont(headingsFontFamily)
  useSetTheme(theme)

  const headingsPhotoSpacing = gallery.spacing?.handle
  useSetHeadingsSpacing(headingsPhotoSpacing)

  useScrollLockController()

  const { isUserAuthorized, setUserAuthorization } = useAuthorization()

  const $setFavoriteLists = useSetFavoriteLists()
  const $favoriteLists = useGetFavoriteLists()

  useEffect(() => {
    setUserAuthorization(isUserAuth)
    $setFavoriteLists(favoriteLists)
  }, [setUserAuthorization, isUserAuth, $setFavoriteLists, favoriteLists])

  useEffect(() => {
    viewsCountIncrement(gallery.id)
  }, [])
  // -Page initialization

  const userAgent = useUserAgent(userAgentString)

  const isFavoritesSectionActivated = checkFavoritesSectionActivation(gallery.settings)
  const isHidingPhotosEnabled = gallery["isHidingPhotosEnabled"]
  const isGrantedHidingPhotos = gallery["isGrantedHidingPhotos"]
  const imageStorageOriginal = IMAGE_STORAGE_ORIGINAL
  // Определяем поддержку webp
  const imgFormat = getImageType() //  если поддержка webp, то запрашиваем .webp, иначе .jpeg
  const imageProxyHost = IMAGE_PROXY_HOST
  const size = SIZE
  const isOnboardingEnabled = checkOnboardingActivation(gallery.settings)
  const [hasDisplayCompleteSelectonButton, currentFavlistId, currentCountPhotos] = useDisplayCompleteSelection({
    favoriteLists: $favoriteLists,
    galleryId: gallery.id,
  })

  const globalState = {
    ...generalSettings,
    isFavoritesSectionActivated,
    isHidingPhotosEnabled,
    isGrantedHidingPhotos,
    domain,
    slug,
    locale,
    userAgent,
    imageProxyHost,
    imageStorageOriginal,
    size,
    imgFormat,
  }

  function handleSubmitCorrectPassword() {
    document.location.reload()
  }

  const { $isPhotoCarouselVisible } = usePhotoCarousel()
  let formattedProductGroups = formatProductGroups(productGroups)

  // Hiding Photos and FavList - visible CompleteSelectonButton
  const [isAuthorizedHidingPhotos, setIsAuthorizedHidingPhotos] = useState(hasDisplayCompleteSelectonButton)
  useEffect(() => {
    setIsAuthorizedHidingPhotos(isUserAuthorized)
  }, [setIsAuthorizedHidingPhotos, isUserAuthorized])
  useEffect(() => {
    setIsAuthorizedHidingPhotos(hasDisplayCompleteSelectonButton)
  }, [setIsAuthorizedHidingPhotos, hasDisplayCompleteSelectonButton])

  // Print Onboarding init (start timeOut and all logic of display)
  initPrintOnboarding()

  return (
    <I18nProvider lngDict={dictionary} locale={locale}>
      <GlobalStateProvider value={globalState}>
        <GalleryProvider value={gallery}>
          <SplitTestingProvider value={splitTestings}>
            <ProductGroupsProvider value={formattedProductGroups}>
              {isInitialyPasswordProtected && <PasswordScreen onSubmitCorrectPassword={handleSubmitCorrectPassword} />}
              {!isInitialyPasswordProtected && (
                <>
                  {isOnboardingEnabled && <Onboarding />}
                  <Gallery />
                  {hasDisplayCompleteSelectonButton && isAuthorizedHidingPhotos && (
                    <CompleteSelection countPhotos={currentCountPhotos} />
                  )}
                  <CommentModal favoritesListId={currentFavlistId} />
                  <SharingModal />
                  <AuthModal />
                  <LimitModal />
                  <DownloadModal location="gallery-page" />
                  <Notification
                    spaceBottomEnabled={
                      !$isPhotoCarouselVisible && hasDisplayCompleteSelectonButton && isAuthorizedHidingPhotos
                    }
                  />
                  <ManualDownloadModal />
                  <ProductsModal />
                  <DownloadProductsModal />
                  {gallery.isCommentsEnabled && <ReviewModal />}
                  <ContextMenu />
                </>
              )}
            </ProductGroupsProvider>
          </SplitTestingProvider>
        </GalleryProvider>
      </GlobalStateProvider>
    </I18nProvider>
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const domain = ctx.req.headers.host
  const slug = ctx.params.slug

  const generalSettings = await fetchGeneralSettings({ requestType: REQUEST_TYPE.SERVER, ctx, domain })
  const { gallery, isPasswordProtected } = await fetchGallery({ requestType: REQUEST_TYPE.SERVER, domain, slug, ctx })

  if (!gallery || !generalSettings) {
    return {
      notFound: true,
    }
  }

  const locale = gallery.lang || LOCALE.DEFAULT
  const { default: dictionary = {} } = await import(`@app/localization/dictionares/${locale}.json`)

  let isUserAuth = false
  let favoriteLists = []
  if (checkAccessToken(ctx)) {
    favoriteLists = await fetchFavoriteLists({ galleryId: gallery.id }, REQUEST_TYPE.SERVER, ctx)
    isUserAuth = true
  }

  const userAgentString = ctx?.req?.headers["user-agent"]

  const productGroups = await getProducts(gallery, ctx)

  // если галерея с паролем, то у нас нет сразу gallery.creator.id, после ввода пароля можем забирать gallery.creator.id
  // const { data: productsPreviewSplitTest } = gallery.creator?.id
  //   ? await fetchSplitTest(
  //       { userId: gallery.creator.id, splitTestingHandle: "PRINT_PRODUCTS_PREVIEW_IMPROVED" },
  //       { requestType: REQUEST_TYPE.SERVER, ctx }
  //     )
  //   : { data: null }

  return {
    props: {
      gallery,
      isPasswordProtected,
      domain,
      slug,
      favoriteLists,
      isUserAuth,
      dictionary,
      locale,
      generalSettings,
      userAgentString,
      productGroups,
      splitTestings: {},
    },
  }
}

export default GalleryPage
