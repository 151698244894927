import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { EffectFade } from "swiper"
import cx from "clsx"
import { useI18n } from "next-localization"

import { isFirstVisit } from "@app/features/onboarding/utils"
import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { IS_FIRST_VISIT_LOCAL_STORAGE_KEY } from "@app/features/onboarding/constants"
import { useGlobalState } from "@app/contexts"

import { Modal } from "@app/ui/modal"
import { Button } from "@app/ui/button"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./onboarding.module.scss"

SwiperCore.use([EffectFade])

function playVideo(video) {
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const playPromise = video.play()
  if (playPromise !== undefined) {
    playPromise.then((_) => {}).catch((error) => {})
  }
}

function Onboarding() {
  const { $isOnboardingVisible, $showOnboarding, $hideOnboarding } = useOnboarding()
  const {
    userAgent: { isMobile },
  } = useGlobalState()

  React.useEffect(() => {
    if (isFirstVisit(IS_FIRST_VISIT_LOCAL_STORAGE_KEY) && !isMobile) {
      $showOnboarding()
    }
  }, [])

  const ref = React.useRef()

  function handleNext() {
    ref?.current?.swiper?.slideNext()
  }

  function handlePrev() {
    ref?.current?.swiper?.slidePrev()
  }

  function goToIndex(index) {
    ref?.current?.swiper?.slideTo(index)
  }

  function handleClose() {
    $hideOnboarding()
    window.localStorage.setItem(IS_FIRST_VISIT_LOCAL_STORAGE_KEY, JSON.stringify(false))
  }

  return (
    <Modal
      open={$isOnboardingVisible}
      crossColor="#000"
      onClose={handleClose}
      crossClassName={QA_CLASS_NAMES.gallery.onboarding.close}
      modalClassName={styles["modal"]}
    >
      <Swiper
        slidesPerView={1}
        ref={ref}
        effect="fade"
        speed={250}
        fadeEffect={{ crossFade: true }}
        breakpoints={{ 1025: { allowTouchMove: false } }}
        updateOnWindowResize={true}
        resizeObserver={true}
      >
        <SwiperSlide className={styles["slide"]}>
          {({ isActive }) => <Step1 isActive={isActive} onNextClick={handleNext} onDotClick={goToIndex}></Step1>}
        </SwiperSlide>
        <SwiperSlide className={styles["slide"]}>
          {({ isActive }) => (
            <Step2 isActive={isActive} onNextClick={handleNext} onPrevClick={handlePrev} onDotClick={goToIndex}></Step2>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles["slide"]}>
          {({ isActive }) => (
            <Step3
              isActive={isActive}
              onPrevClick={handlePrev}
              onCloseClick={handleClose}
              onDotClick={goToIndex}
            ></Step3>
          )}
        </SwiperSlide>
      </Swiper>
    </Modal>
  )
}

function Step1({ isActive, onNextClick, onDotClick }) {
  const ref = React.useRef(null)

  if (ref.current && isActive) {
    playVideo(ref.current)
  }

  const i18n = useI18n()

  return (
    <React.Fragment>
      <video className={styles["video"]} muted playsInline autoPlay ref={ref}>
        <source src={"/onboarding/video/step-1.webm"} type="video/webm"></source>
        <source src={"/onboarding/video/step-1.mp4"} type="video/mp4"></source>
      </video>
      <div className={styles["dots"]}>
        <div className={cx(styles["dot"], styles["active-dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}1`)}></div>
        <div
          className={cx(styles["dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}2`)}
          onClick={() => onDotClick(1)}
        ></div>
        <div
          className={cx(styles["dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}3`)}
          onClick={() => onDotClick(2)}
        ></div>
      </div>
      <div className={styles["inner"]}>
        <h3 className={styles["headline"]}>{i18n.t("onboarding.step-1.title")}</h3>
        <p className={styles["description"]}>{i18n.t("onboarding.step-1.description")}</p>
        <div className={styles["button-group"]}>
          <Button onClick={onNextClick} className={QA_CLASS_NAMES.gallery.onboarding.next}>
            {i18n.t("common.next")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

function Step2({ isActive, onNextClick, onPrevClick, onDotClick }) {
  const ref = React.useRef(null)
  if (ref.current && isActive) {
    playVideo(ref.current)
  }

  const i18n = useI18n()

  return (
    <React.Fragment>
      <video className={styles["video"]} muted playsInline autoPlay ref={ref}>
        <source src={"/onboarding/video/step-2.webm"} type="video/webm"></source>
        <source src={"/onboarding/video/step-2.mp4"} type="video/mp4"></source>
      </video>
      <div className={styles["dots"]}>
        <div
          className={cx(styles["dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}1`)}
          onClick={() => onDotClick(0)}
        ></div>
        <div className={cx(styles["dot"], styles["active-dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}2`)}></div>
        <div
          className={cx(styles["dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}3`)}
          onClick={() => onDotClick(2)}
        ></div>
      </div>
      <div className={styles["inner"]}>
        <h3 className={styles["headline"]}>{i18n.t("onboarding.step-2.title")}</h3>
        <p className={styles["description"]}>{i18n.t("onboarding.step-2.description")}</p>

        <div className={styles["button-group"]}>
          <Button skin="secondary" onClick={onPrevClick} className={QA_CLASS_NAMES.gallery.onboarding.back}>
            {i18n.t("common.back")}
          </Button>
          <Button className={cx(styles["button"], QA_CLASS_NAMES.gallery.onboarding.next)} onClick={onNextClick}>
            {i18n.t("common.next")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

function Step3({ isActive, onPrevClick, onCloseClick, onDotClick }) {
  const ref = React.useRef(null)
  if (ref.current && isActive) {
    playVideo(ref.current)
  }

  const i18n = useI18n()

  return (
    <React.Fragment>
      <video className={styles["video"]} muted playsInline autoPlay ref={ref}>
        <source src={"/onboarding/video/step-3.webm"} type="video/webm"></source>
        <source src={"/onboarding/video/step-3.mp4"} type="video/mp4"></source>
      </video>
      <div className={styles["dots"]}>
        <div
          className={cx(styles["dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}1`)}
          onClick={() => onDotClick(0)}
        ></div>
        <div
          className={cx(styles["dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}2`)}
          onClick={() => onDotClick(1)}
        ></div>
        <div className={cx(styles["dot"], styles["active-dot"], `${QA_CLASS_NAMES.gallery.onboarding.page}3`)}></div>
      </div>
      <div className={styles["inner"]}>
        <h3 className={styles["headline"]}>{i18n.t("onboarding.step-3.title")}</h3>
        <p className={styles["description"]}>{i18n.t("onboarding.step-3.description")}</p>

        <div className={styles["button-group"]}>
          <Button skin="secondary" onClick={onPrevClick} className={QA_CLASS_NAMES.gallery.onboarding.back}>
            {i18n.t("common.back")}
          </Button>
          <Button className={cx(styles["button"], QA_CLASS_NAMES.gallery.onboarding.ok)} onClick={onCloseClick}>
            {i18n.t("common.clear")}
          </Button>
        </div>

        <div className={styles["mobile-button-group"]}>
          <Button className={cx(styles["button"], QA_CLASS_NAMES.gallery.onboarding.ok)} onClick={onCloseClick}>
            {i18n.t("common.goToGallery")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export { Onboarding }
