import * as React from "react"
import cx from "clsx"

import styles from "./input.module.scss"
interface InputInterface extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string
  placeholder?: string
  value?: string
  defaultValue?: string
  className?: string
  id?: string
  name?: string
  register?: any
  isError?: boolean
  required?: boolean
  postfix?: JSX.Element
  mask?: string
  onPostfixClick?: () => void
  postfixClassName?: string
}

function Input({
  type,
  placeholder,
  value,
  defaultValue,
  className,
  id,
  name,
  required,
  postfix,
  register,
  onPostfixClick,
  postfixClassName,
  isError,
  ...rest
}: InputInterface) {
  if (postfix) {
    return (
      <div className={styles["label"]}>
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          ref={register}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={cx(styles["input"], className, {
            [styles["error"]]: isError === true,
          })}
          {...rest}
        ></input>
        <button className={cx(styles["postfix"], postfixClassName)} onClick={onPostfixClick}>
          {postfix}
        </button>
      </div>
    )
  }

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      id={id}
      ref={register}
      name={name}
      className={cx(styles["input"], className, {
        [styles["error"]]: isError === true,
      })}
      {...rest}
    ></input>
  )
}

export { Input }
