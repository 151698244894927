import React from "react"

import styles from "./banner.module.scss"
import { useI18n } from "next-localization"

function Banner({ deleteAt }) {
  const i18n = useI18n()

  return (
    <div className={styles["wrapper"]}>
      <span className={styles["text"]}>
        {i18n.t("banner.text")} <span className={styles["date"]}>{deleteAt}.</span>
      </span>
    </div>
  )
}

export { Banner }
