import React from "react"
import ReactDOM from "react-dom"
import cx from "clsx"

import { Button } from "@app/ui/button"

import styles from "./modal-mobile.module.scss"

type ModalMobileProps = {
  isVisible: boolean
  onClose: any
  title?: string
  description?: string
  buttonText?: string
  className?: string
}

const ModalMobile = ({ isVisible, onClose, title, description, buttonText, className }: ModalMobileProps) => {
  const [isRenderNodeMounted, setIsRenderNodeMounted] = React.useState(false)
  const modalRef = React.useRef<HTMLElement | null>()

  React.useEffect(() => {
    modalRef.current = document.getElementById("modal")
    setIsRenderNodeMounted(true)
  }, [])

  if (isRenderNodeMounted && isVisible) {
    return ReactDOM.createPortal(
      <div className={cx(styles["root"], className)}>
        <div className={styles["content"]}>
          {title && <h3 className={styles["title"]}>{title}</h3>}
          {description && <p className={styles["description"]}>{description}</p>}
          {buttonText && (
            <Button skin="primary" onClick={onClose}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>,
      modalRef.current
    )
  }

  return null
}

export { ModalMobile }
